import { useEffect } from 'react'

import * as Arrays from '@avcan/utils/array'
import { CldImage, getCldImageUrl } from 'next-cloudinary'
import PhotoSwipeLightbox from 'photoswipe/lightbox'

import ImageGallery from 'components/gallery'
import * as Icons from 'components/icons'
import { WHITE } from 'constants/colors'
import * as Context from './Context'
import * as Figures from './Figure'

import styles from './Gallery.module.css'

export default function Gallery() {
    const { images } = Context.useReport()

    const galleryId = 'MINGallery'
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + galleryId,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        lightbox.on('uiRegister', function () {
            lightbox.pswp.ui.registerElement({
                name: 'custom-caption',
                order: 9,
                isButton: false,
                appendTo: 'root',
                html: 'Caption text',
                onInit: (el, pswp) => {
                    lightbox.pswp.on('change', () => {
                        const currSlideElement = lightbox.pswp.currSlide.data.element
                        let captionHTML = ''
                        if (currSlideElement) {
                            captionHTML = currSlideElement.querySelector('img').getAttribute('alt')
                        }
                        el.innerHTML = captionHTML || ''
                        if (!captionHTML) {
                            el.classList.add('no-caption')
                        } else {
                            el.classList.remove('no-caption')
                        }
                    })
                },
            })
        })
        lightbox.init()

        return () => {
            lightbox.destroy()
            lightbox = null
        }
    }, [])

    if (!Array.isArray(images) || images.length === 0) {
        return null
    }

    return (
        <div id={galleryId}>
            <ImageGallery
                renderItem={renderItem}
                items={images}
                showBullets={images.length > 1}
                showPlayButton={images.length > 1}
                showThumbnails={false}
            />
        </div>
    )
}

// Constants and utils
function renderItem({ caption, ...image }) {
    function handleClick() {
        const url = Figures.interpolate(image.url, '9999')

        window.open(url, image.id)
    }

    const srcset = BREAKPOINTS.map(breakpoint => {
        const cldImageUrl = getCldImageUrl({
            src: image.url,
            width: breakpoint,
            height: image.height,
        })
        return `${cldImageUrl} ${breakpoint}w`
    }).join(', ')

    return (
        <Figures.Figure>
            {image.url.startsWith('https://res.cloudinary.com/') ? (
                <a
                    href={image.url}
                    data-pswp-width={image.width}
                    data-pswp-height={image.height}
                    data-pswp-srcset={srcset}
                    key={image.id}
                    style={{
                        aspectRatio: image.width / image.height,
                        position: 'relative',
                    }}
                >
                    <CldImage fill src={image.url} alt={caption} />
                    <button className="image-gallery-icon image-gallery-fullscreen-button">
                        <Icons.Fullscreen color={WHITE} />
                    </button>
                </a>
            ) : (
                <Figures.ImagePending>
                    <Figures.Image {...image} sizes={SIZES} onClick={handleClick} className={styles.Image} />
                </Figures.ImagePending>
            )}
            {caption && <Figures.Caption className={styles.Caption}>{caption}</Figures.Caption>}
        </Figures.Figure>
    )
}
const SIZES = ['(min-width: 1026px) calc(1026px - 2em)', 'calc(100vw - 2em)']
const BREAKPOINTS = Arrays.range(300, 3000, 300)
